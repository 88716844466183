import * as React from "react"
import Navbar from "./Common/Navbar";
import BrandPage from "./BrandPage/BrandPage";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "'Open Sans', sans-serif",
  backgroundColor: '#18182a',
  position: 'relative',
}

const BrandAssets = () => {
  return (
    <main style={pageStyles}>
      <Navbar/>
      <BrandPage/>
    </main>
  )
}

export default BrandAssets
